import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './app.service';
import { HttpClientModule } from '@angular/common/http';
import { AssetPageModule } from './pages/asset/asset.module';
import { ExtractTexturesModule } from './pages/extract-textures/extract-textures.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),

    AppRoutingModule,
    AssetPageModule,
    ExtractTexturesModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    AppService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
