import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ExtractTexturesPage } from './extract-textures.page';
import { ExtractTexturesRoutingModule } from './extract-textures-routing.module';
import { ExtractTexturesService } from './extract-textures.service';

@NgModule({
  declarations: [
    ExtractTexturesPage
  ],
  imports: [
    ExtractTexturesRoutingModule,
    CommonModule,
    IonicModule
  ],
  providers: [
    ExtractTexturesService
  ]
})
export class ExtractTexturesModule { }
