import { Injectable } from '@angular/core';
import { AssetType, IAsset } from '../asset.model';

@Injectable({
  providedIn: 'root'
})
export class PreviewSelectorService {
  private selectedPlant: IAsset;
  private selectedPot: IAsset;

  public getSelection(type: AssetType) {
    if (type === 'plant') {
      return this.selectedPlant;
    } else {
      return this.selectedPot;
    }
  }

  public setSelection(asset: IAsset) {
    if (asset.type === 'plant') {
      this.selectedPlant = asset;
    } else {
      this.selectedPot = asset;
    }
  }
}
