export const availableMaps = [
  "alphaMap",
  "aoMap",
  "bumpMap",
  "displacementMap",
  "emissiveMap",
  "lightMap",
  "map",
  "metalnessMap",
  "normalMap",
  "roughnessMap",
];

import { Group, Mesh } from 'three';

const canvas = document.createElement('canvas');
const context = canvas.getContext("2d");

export async function resizeTextures(scene: Group, size: number, opacity: number = 1): Promise<Group> {
  canvas.width = size;
  canvas.height = size;

  const children = [];
  scene.traverse(async (child: Mesh) => {
    if (!child.isMesh) {
      return;
    }

    children.push(child);
  });

  const images = [];
  await Promise.all(
    children.map(async (child) => {
      const { material } = child;

      await Promise.all(
        availableMaps
          .filter((map) => !!material[map])
          .map((map) => {
            return new Promise((resolve) => {
              const { image } = material[map];
              const { height, width } = image;
              if (width <= size && height <= size) {
                resolve(null);
                return;
              }

              context.drawImage(image, 0, 0, size, size);

              if (opacity < 1) {
                context.globalAlpha = 0.75;
                context.fillStyle = 'lightcyan';
                context.fillRect(0, 0, size, size);
                context.globalAlpha = 1;

                material.opacity = opacity;
                material.transparent = true;
              }

              if (image.close) {
                images.push(image);
              }

              if (typeof createImageBitmap === "function") {
                createImageBitmap(canvas).then((image) => {
                  material[map].image = image;
                  resolve(null);
                });
              } else {
                material[map].image.onload = () => {
                  resolve(null);
                };
                material[map].image.src = canvas.toDataURL("jpg");
              }
            });
          })
      );
    })
  );
  images.forEach((image) => image.close());
  return scene;
}
