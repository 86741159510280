import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAsset } from '../asset/asset.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class ExtractTexturesService {
  constructor(
    private httpClient: HttpClient
  ) { }

  public processAsset(asset: IAsset, token: string) {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/texture/${asset._id}?token=${token}`, {});
  }
}
