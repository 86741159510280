import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AssetPage } from './asset.page';

import { HomePageRoutingModule } from './asset-routing.module';
import { AssetFormComponent } from './asset-form/asset-form.component';
import { AssetEditorComponent } from './asset-editor/asset-editor.component';
import { PreviewSelectorComponent } from './preview-selector/preview-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomePageRoutingModule
  ],
  declarations: [
    AssetPage,

    AssetEditorComponent,
    AssetFormComponent,
    PreviewSelectorComponent,
  ]
})
export class AssetPageModule {}
