import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAsset } from '../asset.model';
import { Router } from '@angular/router';
import { AssetService } from '../asset.service';

@Component({
  selector: 'app-asset-form',
  styleUrls: ['asset-form.component.scss'],
  templateUrl: 'asset-form.component.html'
})
export class AssetFormComponent {
  @Input()
  public asset: IAsset;

  @Output()
  public changeAsset: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public reloadAsset: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  public updateAsset: EventEmitter<null> = new EventEmitter<null>();

  public onChange(): void {
    this.updateAsset.emit();
  }

  public onReloadAsset(): void {
    this.reloadAsset.emit();
  }

  public goToNextAsset(change: number) {
    this.changeAsset.emit(change);
  }
}
