import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IAsset } from '../asset.model';
import { AppService } from '../../../app.service';
import { PreviewSelectorService } from './preview-selector.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-preview-selector',
  styleUrls: ['preview-selector.component.scss'],
  templateUrl: 'preview-selector.component.html'
})
export class PreviewSelectorComponent implements OnChanges {
  @Input()
  public activeAsset: IAsset;

  @Output()
  public previewSelected: EventEmitter<IAsset> = new EventEmitter<IAsset>();

  @Output()
  public swap: EventEmitter<null> = new EventEmitter<null>();

  public availableAssets: IAsset[];
  public selectedAsset: IAsset;

  constructor(private appService: AppService, private previewSelectorService: PreviewSelectorService) {

  }

  public ngOnChanges(): void {
    if (this.activeAsset && this.selectedAsset && this.activeAsset.type !== this.selectedAsset.type) {
      return;
    }

    this.availableAssets = this.appService.getAssets().filter(asset => asset.type !== this.activeAsset.type);
    this.selectedAsset = this.previewSelectorService.getSelection(this.activeAsset.type === 'plant' ? 'pot' : 'plant');
    this.selectedAsset = this.availableAssets.find(asset => asset._id === this.selectedAsset?._id);

    this.onAssetSelect();
  }

  public onAssetSelect() {
    if (this.selectedAsset) {
      this.previewSelectorService.setSelection(this.selectedAsset);
    }

    this.previewSelected.emit(this.selectedAsset);
  }

  public onSwapClick() {
    this.previewSelectorService.setSelection(this.activeAsset);
    this.swap.emit();
  }

  public changePreviewAsset(change: -1 | 1) {
    if (!this.selectedAsset) {
      this.selectedAsset = change === -1 ? this.availableAssets[this.availableAssets.length - 1] : this.availableAssets[0];
    } else {
      const index = this.availableAssets.findIndex(asset => asset === this.selectedAsset) + change;
      if (index === -1 || index === this.availableAssets.length) {
        this.selectedAsset = null;
      } else {
        this.selectedAsset = this.availableAssets[index];
      }
    }

    this.onAssetSelect();
  }

  public gotoQRCode() {
    const plant = this.selectedAsset.type === 'plant' ? this.selectedAsset.sku : this.activeAsset.sku;
    const pot = this.selectedAsset.type === 'pot' ? this.selectedAsset.sku : this.activeAsset.sku;

    window.open(`${environment.apiUrl}/qr?plant=${plant}&pot=${pot}`);
  }
}
