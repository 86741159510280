import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DirectionalLight, HemisphereLight,
  Object3D,
  PerspectiveCamera,
  Scene,
  WebGLRenderer
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { IAsset } from '../asset.model';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { ToastController } from '@ionic/angular';
import { resizeTextures } from './asset-editor.utils';

@Component({
  selector: 'app-asset-editor',
  styleUrls: ['asset-editor.component.scss'],
  templateUrl: 'asset-editor.component.html'
})
export class AssetEditorComponent implements OnInit {
  @Input()
  public asset: IAsset;

  @Input()
  public previewAsset: IAsset;

  @Output()
  public updateAsset: EventEmitter<IAsset> = new EventEmitter<IAsset>();

  public isLoading: boolean = false;
  public progressValue: number = 0;

  private isMovingTransformControls = false;

  private camera: PerspectiveCamera;
  private canvas: HTMLCanvasElement;
  private controls: OrbitControls;
  private loader: GLTFLoader;
  private model: Object3D;
  private modelSrc: string;
  private previewModel: Object3D;
  private previewModelSrc: string;
  private renderer: WebGLRenderer;
  private scene: Scene;
  private transformControls: TransformControls;
  private transformObject: Object3D;

  constructor(
    private elementRef: ElementRef,
    private toastController: ToastController,
  ) { }

  public ngOnInit() {
    this.canvas = this.elementRef.nativeElement.querySelector('canvas');
    this.animate = this.animate.bind(this);
    this.onTransformControlsChange = this.onTransformControlsChange.bind(this);
    this.onTranformControlsMouseDown = this.onTranformControlsMouseDown.bind(this);
    this.onTranformControlsMouseUp = this.onTranformControlsMouseUp.bind(this);
    this.setupCanvas = this.setupCanvas.bind(this);

    const { height, width } = this.canvas.getBoundingClientRect();
    if (width === 0) {
      setTimeout(() => this.ngOnInit(), 500);
      return;
    }

    this.canvas.width = width;
    this.canvas.height = height;

    this.scene = new Scene();
    this.camera = new PerspectiveCamera( 75, width / height, 0.1, 1000 );

    this.renderer = new WebGLRenderer({ canvas: this.canvas });
    this.renderer.setClearColor( 0xdddddd, 1 );

    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.loader = new GLTFLoader();

    this.model = new Object3D()
    this.scene.add( this.model );

    this.transformObject = new Object3D();
    this.transformObject.position.set(this.asset.anchor.x, this.asset.anchor.y, this.asset.anchor.z);
    this.transformControls = new TransformControls( this.camera, this.renderer.domElement );
    this.transformControls.addEventListener( 'objectChange', this.onTransformControlsChange );
    this.transformControls.addEventListener( 'mouseDown', this.onTranformControlsMouseDown );
    this.transformControls.addEventListener( 'mouseUp', this.onTranformControlsMouseUp );

    this.transformControls.attach(this.transformObject);
    this.scene.add(this.transformObject);
    this.scene.add(this.transformControls);

    const hemiLight = new HemisphereLight( 0xffffff, 0x444444 );
    hemiLight.position.set( 0, 10, 0 );
    this.scene.add( hemiLight );

    const dirLight = new DirectionalLight( 0xffffff );
    dirLight.position.set( 7.5, 30, -7.5 );
    this.scene.add( dirLight );

    this.camera.position.z = 1;

    this.animate();
    this.loadAsset();
    this.loadPreviewAsset();

    window.addEventListener('resize', this.setupCanvas);
  }

  public ngOnChanges() {
    console.log('Change', this.previewAsset);

    if (!this.asset || !this.loader) {
      return;
    }

    if (this.model && this.asset.modelSrc !== this.modelSrc) {
      this.loadAsset();
    }

    if (this.previewAsset && this.previewAsset.modelSrc !== this.previewModelSrc) {
      this.loadPreviewAsset();
    }

    if (!this.previewAsset) {
      this.previewModel?.clear();
    }

    if (this.transformObject) {
      this.transformObject.position.set(this.asset.anchor.x, this.asset.anchor.y, this.asset.anchor.z);
    }
  }

  private animate() {
    requestAnimationFrame( this.animate );

    if (!this.isMovingTransformControls) {
      this.controls.update();
    }
    this.renderer.render( this.scene, this.camera );
  }

  private setupCanvas() {
    const { height, width } = this.canvas.getBoundingClientRect();

    this.canvas.width = width;
    this.canvas.height = height;

    this.camera.aspect = width / height;
  }

  private async loadAsset() {
    let { modelSrc } = this.asset;

    if (!modelSrc) {
      return;
    }

    this.isLoading = true;
    this.progressValue = 0;
    this.model.clear();
    this.modelSrc = modelSrc;

    this.loader.load(modelSrc, async gltf => {
      gltf.scene = await resizeTextures(gltf.scene, 64);
      this.isLoading = false;
      this.model.add(gltf.scene)
    }, ({ loaded, total }) => {
      this.progressValue = loaded / total;
    }, async (error) => {
      this.isLoading = false;
      console.error('Error while loading model', error);
      const toast = await this.toastController.create({
        message: `Error while loading model: ${error}`,
        duration: 2000
      });
      await toast.present();
    });
  }

  private async loadPreviewAsset() {
    if (!this.previewAsset) {
      return;
    }

    let { modelSrc } = this.previewAsset;

    if (!modelSrc) {
      return;
    }

    this.previewModel?.clear();
    this.previewModelSrc = modelSrc;

    this.loader.load(modelSrc, async gltf => {
      if (modelSrc !== this.previewAsset.modelSrc) {
        gltf.scene.clear();
        return;
      }

      gltf.scene = await resizeTextures(gltf.scene, 64, 0.5);
      gltf.scene.position.set(
        this.previewAsset.anchor.x * -1,
        this.previewAsset.anchor.y * -1,
        this.previewAsset.anchor.z * -1,
      )
      this.transformObject.add(gltf.scene)

      this.previewModel = gltf.scene;
    });
  }

  private onTransformControlsChange(e) {
    this.asset.anchor = {
      x: e.target.object.position.x,
      y: e.target.object.position.y,
      z: e.target.object.position.z
    }
  }

  private onTranformControlsMouseDown() {
    this.controls.enabled = false;
  }

  private onTranformControlsMouseUp() {
    this.controls.enabled = true;
    this.updateAsset.emit();
  }
}
