import { Component, OnInit } from '@angular/core';
import { IAsset } from '../asset/asset.model';
import { AppService } from '../../app.service';
import { ExtractTexturesService } from './extract-textures.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-extract-textures-page',
  templateUrl: 'extract-textures.page.html',
  styleUrls: ['extract-textures.page.scss'],
})
export class ExtractTexturesPage implements OnInit {
  public assets: IAsset[];
  public currentAsset: number;
  public progress: number[] = [];

  constructor(
    private appService: AppService,
    private extractTexturesService: ExtractTexturesService,
  ) { }

  public ngOnInit() {
    this.assets = this.appService.getAssets();
    console.log(this.assets);
  }

  public startExtracting() {
    this.currentAsset = 0;
    this.progress = [];

    this.extractNextItem();
  }

  private extractNextItem() {
    this
      .extractTexturesService
      .processAsset(this.assets[this.currentAsset], this.appService.getToken())
      .pipe(
        catchError(e => {
          this.progress.push(-1);
          this.currentAsset++;
          this.extractNextItem();
          return e;
        })
      )
      .subscribe(result => {
        this.progress.push(result ? 1 : -1);
        this.currentAsset++;
        this.extractNextItem();
      })
  }
}
