import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IAsset } from './asset.model';

@Component({
  selector: 'app-home',
  templateUrl: 'asset.page.html',
  styleUrls: ['asset.page.scss'],
})
export class AssetPage implements OnInit {
  public asset: IAsset;
  public previewAsset: IAsset;

  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
  ) { }

  public ngOnInit() {
    const { id } = this.activatedRoute.snapshot.params;
    this.asset = this.appService.getAsset(id);

    if (this.asset && !this.asset.anchor) {
      this.asset.anchor = { x: 0, y: 0, z: 0 };
    }
  }

  public changeAsset(change: number) {
    const newAsset = this.appService.getNextAsset(this.asset._id, change);
    if (!newAsset) {
      return;
    }

    this.asset = {...newAsset};
  }

  public reloadAsset() {
    this.asset = {...this.asset};
  }

  public swapAssets() {
    const { asset, previewAsset } = this;
    this.asset = previewAsset;
    this.previewAsset = asset;
  }

  public updateAsset() {
    this.asset = {...this.asset};
    this.appService.updateAsset(this.asset).subscribe(() => { });
  }
}
