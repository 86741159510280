import { Injectable } from '@angular/core';
import { AssetType, IAsset } from './pages/asset/asset.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { tap } from 'rxjs/operators';

const TOKEN_KEY = 'maxifleur-admin.token';

@Injectable()
export class AppService {
  private assets: IAsset[];

  constructor(private httpClient: HttpClient) { }

  public fetchAssets() {
    return this.httpClient.get<IAsset[]>(`${environment.apiUrl}/asset?token=${this.getToken()}`).pipe(
      tap(assets => {
        this.assets = assets;
    }));
  }

  public getAssets() {
    return this.assets;
  }

  public getAsset(id: string) {
    return this.assets.find(asset => asset._id === id);
  }

  public getNextAsset(id: string, change: number) {
    const index = this.assets.findIndex(asset => asset._id === id) + change;
    while(change < 0) {
      change+=this.assets.length;
    }

    while(change >= this.assets.length) {
      change-=this.assets.length;
    }

    return this.assets[index];
  }

  public createAsset(type: AssetType) {
    return this.httpClient.post<IAsset>(`${environment.apiUrl}/asset?token=${this.getToken()}`, { type }).pipe(
      tap(asset => {
        this.assets.push(asset);
      })
    );
  }

  public updateAsset(asset: IAsset) {
    const index = this.assets.findIndex(search => search._id === asset._id);
    this.assets[index] = asset;
    return this.httpClient.post(`${environment.apiUrl}/asset/${asset._id}?token=${this.getToken()}`, asset);
  }

  public verifyToken() {
    const token = this.getToken();
    return this.httpClient.post(`${environment.apiUrl}/asset/verify?token=${token}`, {});
  }

  public getToken() {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveToken(token: string) {
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public exportDatabase() {
    const anchor = document.createElement("a");
    const file = new Blob([JSON.stringify(this.assets)], {type: 'text/plain'});
    anchor.href = URL.createObjectURL(file);
    anchor.download = `maxifleur-${new Date().getTime()}.json`;
    anchor.click();
  }
}
