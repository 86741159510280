import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public assetsFetched = false;

  constructor(
    private appService: AppService,
    private alertController: AlertController,
    private loadingController: LoadingController,
  ) { }

  public async ngOnInit() {
    const token = this.appService.getToken();
    if (!token) {
      await this.askToken();
    } else {
      await this.verifyToken();
    }
  }

  private async askToken() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Enter token',
      inputs: [
        {
          name: 'token',
          type: 'text',
          placeholder: 'Token'
        }
      ],
      buttons: [
        {
          text: 'Ok',
          handler: (input) => {
            this.appService.saveToken(input.token);
            this.verifyToken();
          }
        }
      ]
    });

    await alert.present();
  }

  private async verifyToken() {
    this.appService.verifyToken().pipe(
      tap(response => {
        if (response) {
          this.loadAssets()
        } else {
          this.askToken()
        }
      }),
      catchError(() => this.askToken())
    ).subscribe();
  }

  private async loadAssets() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading.present();

    this.appService.fetchAssets().subscribe(async () => {
      await loading.dismiss();
      this.assetsFetched = true;
    });
  }
}
